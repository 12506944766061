import { Logger, inspect } from 'aven_shared/src/main'
import { appSessionStorage, sessionStorageKey } from './storage'

const getMetadata = (): Record<string, string> => {
    const metadata: Record<string, string> = {}
    const applicantId = appSessionStorage.getItem(sessionStorageKey.applicantId)
    if (applicantId) {
        metadata.applicantId = applicantId
    }
    const loanApplicationId = appSessionStorage.getItem(sessionStorageKey.loanApplicationId)
    if (loanApplicationId) {
        metadata.loanApplicationId = loanApplicationId
    }
    const sessionId = appSessionStorage.getItem(sessionStorageKey.sessionId)
    if (sessionId) {
        metadata.sessionId = sessionId
    }
    const sessionRecordingUrl = appSessionStorage.getItem(sessionStorageKey.sessionRecordingUrl)
    if (sessionRecordingUrl) {
        metadata.sessionRecordingUrl = sessionRecordingUrl
    }
    return metadata
}

const logger = new Logger({
    avenProject: 'aven-auto',
    sentryDSN: process.env.VUE_APP_SENTRY_ID ?? '',
    logProcessorBaseUrl: process.env.VUE_APP_LOGGER_BASE_URL,
    clientSideLogsEnabled: process.env.VUE_APP_CLIENT_SIDE_LOGS_ENABLED === 'yes',
    getMetadata,
})

export { logger, inspect }
